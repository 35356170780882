import { useParams } from "react-router-dom";
import { fileUrl, handleClick, useFETCH, usePOST } from "../../APIs/useMyAPI";
import { Back, Container, Loading } from "../../components";
import AddImages from "../../components/AddImage/AddImages";

function OurArtistPastExhibitionAdd() {
  const { id, idU } = useParams();
  const {
    setFormData,
    handleChange,
    loading,
    handleSubmit,
    img,
    error,
    setImg,
  } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(
      isNaN(idU) ? `artists/${id}/_/images` : `artists/files/${idU}`
    );
  };
  const { data } = useFETCH(`artists/files/${idU}`);
  let dataUp = data?.data.data;

  return (
    <Container>
      {loading ? <Loading /> : ""}
      <div className="flex flex-col">
        <div className="mt-8">
          <AddImages
            onChange={handleChange}
            name="file"
            image={"Add Photo"}
          ></AddImages>
        </div>
        <div className="flex justify-center my-4">
          {img && (
            <div className="relative">
              <div
                onClick={() => {
                  setImg("");
                  setFormData("");
                }}
                className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
              >
                X
              </div>
              <img 
              onClick={handleClick}
                className="w-[300px] h-[220px] mx-auto rounded-2xl"
                src={img}
                alt=""
              />
            </div>
          )}
          {isNaN(idU) ? (
            ""
          ) : (
            <div className="relative mx-4">
              <img onClick={handleClick}
                className="w-[300px] h-[220px] mx-auto rounded-2xl mb-6"
                src={fileUrl + dataUp?.file}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
      <div className="text-red-500 font-semibold ">{error}</div>
      <Back onClick={handleFormSubmit}>Save</Back>
    </Container>
  );
}
export default OurArtistPastExhibitionAdd;
