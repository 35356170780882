import ReactPaginate from "react-paginate";
import { fileUrl, handleClick, useFETCH } from "../../APIs/useMyAPI";
import { Container, Add, CardAll, Loading } from "../../components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
function Events() {
  const { data, isLoading, handlePageClick, deleteItem } = useFETCH("events");
  return (
    <Container>
      <div className="flex justify-center flex-wrap gap-2 mb-24">
        {data?.data.data.data == 0 ? (
          <div className="font-semibold flex items-center h-[50vh] text-2xl">
            There are no items to show
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <Loading />
        ) : (
          data?.data.data.data.map((e) => (
            <CardAll
              key={e.id}
              delete={() => deleteItem(e)}
              update={`/events/${e.id}`}
            >
              <div className=" bg-white max-sm:max-w-full w-[400px]">
                <div className="text-center">
                  <span className="font-bold text-lg">{e.prefix}</span>
                  <h1 className="fontsTitle font-semibold text-5xl py-3">
                    {e.name}
                  </h1>
                  <span className="text-gray-900 text-lg">{e.invitation}</span>
                  <h4 className="fontsTitle colorRed text-red-600 py-2 font-semibold text-2xl">
                    {e.invitation_name}
                  </h4>
                  <p className="text-gray-400 font-medium">{e.date}</p>
                  <p className="text-gray-400 font-medium py-2">{e.time}</p>
                  <p className="font-medium">{e.location}</p>
                  <span className="font-medium">{e.phones_numbers}</span>
                </div>
                <img
                  className="mx-auto my-2 rounded-2xl overflow-hidden  w-full object-fill h-[300px]"
                  src={fileUrl + e._360_image}
                  alt=""
                />
                <Swiper slidesPerView={2.3}>
                  {e.images?.map((e2) => (
                    <SwiperSlide>
                      <img
                        onClick={handleClick}
                        className="w-[130px] h-[150px] rounded-2xl"
                        src={fileUrl + e2.image}
                        alt=""
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </CardAll>
          ))
        )}
      </div>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link={"/events/addEvents"}>Add New Exhibition</Add>
    </Container>
  );
}
export default Events;
