import { useState } from "react";
import { Back, Container, Loading } from "../../components";
import AddImages from "../../components/AddImage/AddImages";
import addIcon from "../../images/+.png";
import { fileUrl, handleClick, useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

function AddMediaCenter() {
  const { id } = useParams();
  const [active, setActive] = useState("");
  const {
    handleChange,
    loading,
    error,
    handleSubmit,
    setFormData,
    setImg,
    img,
  } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(isNaN(id) ? "media-center/files" : `media-center/files/${id}`);
  };
  const { data } = useFETCH(`media-center/files/${id}`);

  return (
    <Container>
      {loading ? <Loading /> : ""}
      <div className="flex space-x-3 justify-center">
        <div className="flex items-center">
          <input
            type="radio"
            value="image"
            onChange={handleChange}
            name="type"
            onClick={() => {
              setImg("");
              setFormData("");
              setActive("image");
            }}
            className="h-5 w-5 mx-2 "
          />
          <span>Photo</span>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            value="video"
            onChange={handleChange}
            name="type"
            onClick={() => {
              setImg("");
              setFormData("");
              setActive("video");
            }}
            className="h-5 w-5  mx-2 "
          />
          <span>Video</span>
        </div>
      </div>
      {active === "image" ? (
        <div className="mt-8">
          <AddImages
            onChange={handleChange}
            name="file"
            image={"Add Photo"}
          ></AddImages>
          {img && (
            <div className={`w-full  mb-5 mx- ${img == 0 ? "hidden" : ""}`}>
              <div img="">
                <div className="relative w-[150px] h-[150px] mx-auto my-4">
                  <div
                    onClick={() => {
                      setImg("");
                      setFormData("");
                    }}
                    className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                  >
                    X
                  </div>
                  <img
                  onClick={handleClick}
                    className="w-[150px] h-[150px]  mx-auto  rounded-2xl"
                    src={img}
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      {/* ---------------------------------------------------------------------------- */}
      {active === "video" ? (
        <>
          <div className="min-h-[80px] w-[50%] bg-white max-sm:w-[80%] border-2 font-semibold border-dashed border-Brown mx-auto rounded-lg mt-8 max-sm:mb-40">
            <div
              className={`${
                img ? "hidden " : "flex items-center justify-center"
              }`}
            >
              <input
                id="OurArtist4"
                type="file"
                name="file"
                onChange={handleChange}
                className="hidden"
                accept="video/mp4,video/x-m4v,video/*"
                multiple
              />
              <button
                onClick={() => document.getElementById("OurArtist4").click()}
                className="block w-full h-full"
              >
                <di className="flex items-center justify-between mx-5 py-3">
                  <p className="text-gray-300 text-xl">add video</p>
                  <img src={addIcon} alt="" className="w-8" />
                </di>
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {isNaN(id) ? (
        ""
      ) : (
        <div className="relative my-4">
          {data?.data.data.type === "image" && (
            <img 
            onClick={handleClick}
              className="w-[300px] h-[220px] mx-auto rounded-2xl mb-6"
              src={fileUrl + data?.data.data.file}
              alt=""
            />
          )}
          {data?.data.data.type === "video" && (
            <video
              controls
              className="w-[280px] h-[250px] mx-auto object-fill rounded-lg"
            >
              <source src={fileUrl + data?.data.data.file} type="video/mp4" />
            </video>
          )}
        </div>
      )}
      <div className="text-red-500 font-semibold ">{error}</div>
      <Back onClick={handleFormSubmit}>Save</Back>
    </Container>
  );
}
export default AddMediaCenter;
