import { fileUrl, handleClick, useFETCH } from "../../APIs/useMyAPI";
import { Add, Border, CardAll, Container, Loading } from "../../components";
import parse from "html-react-parser";
function Information() {
  const { data } = useFETCH(`site-info/home`);
  const { data: data2, isLoading } = useFETCH(`home/slider/files`);

  return (
    <Container>
      <div className="flex flex-wrap justify-center gap-5 mb-14 ">
        <div className="p-2 border-[3px] w-[30%] text-xl max-lg:w-[48%] max-sm:w-full border-Brown rounded-2xl">
          <span className="text-Brown font-bold text-xl pl-2 pr-3">
            Title and Description :{" "}
          </span>
          {parse(`${data?.data.data.home_description}`)}
        </div>

        <div className="p-2 border-[3px] w-[30%] text-xl max-lg:w-[48%] max-sm:w-full border-Brown rounded-2xl">
          <span className="text-Brown font-bold text-xl pl-2 pr-3">
            About Us :{" "}
          </span>{" "}
          {parse(`${data?.data.data.home_about_us}`)}
        </div>

        <div className="p-2 border-[3px] w-[30%] text-xl max-lg:w-[48%] max-sm:w-full border-Brown rounded-2xl">
          <span className="text-Brown font-bold text-xl pl-2 pr-3">
            Location :{" "}
          </span>
          {parse(`${data?.data.data.location}`)}
        </div>

        <CardAll update="/information/about_us_image">
          <span className="text-Brown text-center block mb-3 font-bold text-xl pl-2 pr-3">
            About Us Image
          </span>
          <img
            src={fileUrl + data?.data.data.about_us_image}
            alt=""
            className="w-[250px] h-[250px]"
          />
        </CardAll>
        <CardAll update="/information/footer_image">
          <span className="text-Brown text-center block mb-3 font-bold text-xl pl-2 pr-3">
            Footer Image
          </span>
          <img
            src={fileUrl + data?.data.data.footer_image}
            alt=""
            className="w-[250px] h-[250px]"
          />
        </CardAll>
      </div>
      <div className="w-fit mx-auto">
        <Border>
          {isLoading ? <Loading /> : ""}
          <div className="flex flex-wrap gap-2 p-3">
            {data2?.data.data == 0 ? (
              <div className="font-semibold flex items-center h-[50vh] text-2xl">
                There are no items to show
              </div>
            ) : (
              ""
            )}
            {data2?.data.data.map((e) => (
              <div key={e.id}>
                {e.type === "image" ? (
                  <img
                    onClick={handleClick}
                    className="w-[200px] h-[200px] mx-auto rounded-2xl"
                    src={fileUrl + e.file}
                    alt=""
                  />
                ) : (
                  <video
                    controls
                    className="w-[200px] h-[200px] object-cover  mx-auto rounded-2xl"
                  >
                    <source src={fileUrl + e.file} type="video/mp4" />
                  </video>
                )}
              </div>
            ))}
          </div>
        </Border>
      </div>

      <Add link={"/information/add"}>Edit</Add>
    </Container>
  );
}
export default Information;
