import React, { useEffect } from "react";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import AddImages from "../../components/AddImage/AddImages";
import { Back, Container, Loading } from "../../components";
import { useParams } from "react-router-dom";

const ImagesHome = () => {
  const { id } = useParams();
  const {
    loading,
    error,
    setImg,
    handleChange,
    handleSubmit,
    setFormData,
    img,
  } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit("site-info/home");
  };
  const { data } = useFETCH(`site-info/home`);
  let dataUp = data?.data.data;
  useEffect(() => {
    setFormData({
      home_description: dataUp?.home_description,
      home_about_us: dataUp?.home_about_us,
      location: dataUp?.location,
    });
  }, [dataUp]);
  return (
    <Container>
      <div className="mt-8">
        {loading ? <Loading /> : ""}
        <AddImages
          onChange={handleChange}
          name={`${id}`}
          image={"Photo"}
        ></AddImages>
        {img && (
          <div className={`w-full mb-5 `}>
            <div img="">
              <div className="relative w-[150px] h-[150px] mx-auto my-4">
                <div
                  onClick={() => {
                    setImg("");
                    setFormData("");
                  }}
                  className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                >
                  X
                </div>
                <img
                  className="w-[150px] h-[150px]  mx-auto  rounded-2xl"
                  src={img}
                  alt=""
                />
              </div>
            </div>
          </div>
        )}
        <Back onClick={handleFormSubmit}>Save</Back>
      </div>
      <div className="text-red-500 font-semibold">{error}</div>
    </Container>
  );
};

export default ImagesHome;
