import { BiMenu } from "react-icons/bi";
import Logo from "../../images/1-1.png";
import { useContextHook } from "../../Context/ContextOPen";
import ButtonApp from "../Button/ButtonApp";
import { useState } from "react";
import { Link } from "react-router-dom";
import {  usePOST } from "../../APIs/useMyAPI";

const Navbar = () => {
  const { changeMenu } = useContextHook();
  const [sure, setSure] = useState(false);
  const { handleSubmit } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit("admin/logout");
    window.localStorage.clear()
  };

  return (
    <>
      <div
        onClick={() => setSure(false)}
        className={`${
          sure ? "" : "hidden"
        } fixed w-full  h-full top-0 left-0 popup z-50 flex justify-center items-center`}
      >
        <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
          <p className="font-semibold text-3xl text-center py-7">
            هل انت متأكد من تسجيل الخروج؟
          </p>
          <div className="flex items-end m-5">
            <Link to="/login" onClick={handleFormSubmit}>
              <button className=" border px-10 border-Brown bg-Brown text-white font-semibold  p-3 rounded-xl">
                نعم
              </button>
            </Link>
            <button
              onClick={() => setSure(false)}
              className=" border px-10 border-Brown text-Brown bg-white font-semibold  p-3 rounded-xl ml-5"
            >
              تراجع
            </button>
          </div>
        </div>
      </div>

      <div className="container mx-auto sticky top-0 z-40 bg-white flex items-center justify-between flex-wrap mb-5 pb-2 border-b-2 border-Brown">
        <div>
          <BiMenu
            size={35}
            className="text-Brown cursor-pointer"
            onClick={changeMenu}
          />
        </div>
        <div className="">
          <img src={Logo} alt="" className="w" />
        </div>
        <div className="flex gap-9 mx-3">
          <ButtonApp onClick={() => setSure(true)}>Log Out</ButtonApp>
        </div>
      </div>
    </>
  );
};

export default Navbar;
