function Input(props) {
  return (
    <input
      type={props.type || "text"}
      name={props.name}
      value={props.value}
      placeholder={props.title}
      onChange={props.onChange}
      className="text-Gray font-semibold px-2 outline-none border-2 border-Brown rounded-xl py-5 w-full block"
    />
  );
}
export default Input;
