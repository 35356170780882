import { Container, Loading } from "../../components/index";

import { Link } from "react-router-dom";
import { MdOutlineLanguage, MdOutlineMailOutline } from "react-icons/md";
import {
  AiFillYoutube,
  AiOutlineWhatsApp,
  AiTwotonePhone,
} from "react-icons/ai";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { useFETCH } from "../../APIs/useMyAPI";

const ContactUs = () => {
  const { data, isLoading } = useFETCH(`site-info/media`);
;
  return (
    <>
      <Container>
        {isLoading ? <Loading /> : ""}
        <div className="flex justify-end w-[100%]">
          <Link to="/contactUs/add">
            <div className="bg-Brown text-white font-semibold text-2xl rounded-xl py-4 text-center w-[100px] mb-5">
              Edit
            </div>
          </Link>
        </div>
        <div className="flex flex-wrap mx-auto gap-5">
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <FaInstagram size={60} className="" color="#CB3878" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.instgram.substring(0,30)}
            </p>
          </div>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <FaFacebook size={60} className="" color="#3B5998" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.facebook.substring(0,30)}
            </p>
          </div>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <AiFillYoutube size={60} className="" color="#E21A20" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.youtube.substring(0,30)}
            </p>
          </div>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <AiOutlineWhatsApp size={60} className="" color="#0DC143" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.instgram.substring(0,30)}
            </p>
          </div>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <FaTwitter size={60} className="" color="#2DAAE1" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.twitter.substring(0,30)}
            </p>
          </div>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <MdOutlineMailOutline size={60} className="" color="#707070" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.email.substring(0,30)}
            </p>
          </div>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <AiTwotonePhone size={60} className="" color="#FEDADA" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.phone_number1.substring(0,30)}
            </p>
          </div>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <AiTwotonePhone size={60} className="" color="#9F7CC6" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.phone_number2.substring(0,30)}
            </p>
          </div>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <AiTwotonePhone size={60} className="" color="#CB3878" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.phone_number3.substring(0,30)}
            </p>
          </div>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white">
            <MdOutlineLanguage size={60} className="" color="#1B171F" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {data?.data.data.website_url.substring(0,30)}
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ContactUs;
