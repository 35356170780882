import { useParams } from "react-router-dom";
import { Add, CardAll, Container, Loading } from "../../components";
import SecondNavbar from "../../components/Navbar/SecondNavbar";
import { fileUrl, handleClick, useFETCH } from "../../APIs/useMyAPI";

function OurArtistBiography() {
  const { id } = useParams();

  const { data, isLoading } = useFETCH(`artists/${id}`);
  return (
    <Container>
      <SecondNavbar />
      {data?.data.data == 0 ? (
        <div className="font-semibold flex items-center h-[50vh] text-2xl">
          There are no items to show
        </div>
      ) : (
        ""
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex justify-center flex-wrap gap-5 mt-5 mb-20">
          <img
            onClick={handleClick}
            src={fileUrl + data?.data.data.biography_image}
            alt=""
            className="w-[300px] object-contain rounded-2xl"
          />
          <div className="border-[3px] border-Brown rounded-2xl w-full p-2 text-xl text-center">
            <span className="text-2xl text-Brown">Message : </span>
            {data?.data.data.biography_text}
          </div>
        </div>
      )}

      <Add link={`/ourArtist/${id}/next-biography/edit`}>Edit</Add>
    </Container>
  );
}
export default OurArtistBiography;
