import {
  MediaCenter,
  AddMediaCenter,
  ContactUs,
  ContactUsTwo,
  Events,
  AddEvents,
  Exhibition,
  AddExhibition,
  News,
  AboutUs,
  AboutUsAdd,
  OurArtist,
  OurArtistAdd,
  OurArtistPress,
  OurArtistVideo,
  OurArtistPressAdd,
  OurArtistVideoAdd,
  OurArtistPastExhibitionAdd,
  OurArtistBiographyAdd,
  OurArtistPastExhibition,
  OurArtistBiography,
  AddNews,
  Information,
  InformationAdd,
  ChangePassword,
  Messages,
  ImagesHome,
} from "./pages/index";
import { Navbar, SideBar } from "./components/index";
import { Route, Routes } from "react-router-dom";
import Logo from "./images/1-1.png";
import backGr from "./images/backGr.png";
import Login from "./pages/Login/Login";
import { RequireAuth, useFETCH } from "./APIs/useMyAPI";

const App = () => {
  const { data } = useFETCH("admin/auth/check");
  
  return (
    <>
      <div className="relative flex">
        <SideBar />
        <img
          src={backGr}
          alt=""
          className="absolute -z-10 bottom-0 -right-0"
        />
        <div className="container mx-auto relative">
          <Navbar />
          <Routes>
            <Route path={"/login"} element={<Login />} />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <h1 className="grid place-content-center h-[80vh]">
                    <img src={Logo} alt="" className="w-[250px]" />
                  </h1>
                </RequireAuth>
              }
            />
            <Route
              path="*"
              element={
                <RequireAuth>
                  <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                    404 | no page found
                  </h1>
                </RequireAuth>
              }
            />
            <Route
              path="403"
              element={
                <RequireAuth>
             
                  <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                    403 | FORBIDDEN
                  </h1>
                </RequireAuth>
              }
            />
            <Route
              path="500"
              element={
                <RequireAuth> <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                  500 | Internal Server Error
                </h1></RequireAuth>
               
              }
            />
            <Route path="contactUs" element={ <RequireAuth><ContactUs /></RequireAuth>} />
            <Route path="contactUs/add" element={<ContactUsTwo />} />

            <Route path="events" element={ <RequireAuth><Events /></RequireAuth>} />
            <Route path="events/:id" element={<RequireAuth><AddEvents /></RequireAuth>} />
            <Route path="aboutUs" element={ <RequireAuth><AboutUs /></RequireAuth>} />
            <Route path="aboutUs/add" element={ <RequireAuth><AboutUsAdd /></RequireAuth>} />
            <Route path="exhibition" element={<RequireAuth><Exhibition /></RequireAuth>} />
            <Route path="exhibition/:id" element={<RequireAuth><AddExhibition /></RequireAuth>} />
            <Route path="news" element={<RequireAuth><News /></RequireAuth>} />
            <Route path="news/:id" element={<RequireAuth><AddNews /></RequireAuth>} />
            <Route path="ourArtist" element={<RequireAuth><OurArtist /></RequireAuth>} />
            <Route path="ourArtist/:id" element={<RequireAuth><OurArtistAdd /></RequireAuth>} />
            <Route
              path="ourArtist/:id/next-press"
              element={<RequireAuth><OurArtistPress /></RequireAuth>}
            />
            <Route
              path="ourArtist/:id/next-press/:idU"
              element={ <RequireAuth><OurArtistPressAdd /></RequireAuth>}
            />
            <Route
              path="ourArtist/:id/next-video"
              element={<RequireAuth><OurArtistVideo /> </RequireAuth>}
            />
            <Route
              path="ourArtist/:id/next-video/:idU"
              element={<RequireAuth><OurArtistVideoAdd /> </RequireAuth>}
            />
            <Route
              path="ourArtist/:id/next-pastExhibition"
              element={<RequireAuth><OurArtistPastExhibition /> </RequireAuth>}
            />
            <Route
              path="ourArtist/:id/next-pastExhibition/:idU"
              element={<RequireAuth><OurArtistPastExhibitionAdd /> </RequireAuth>}
            />
            <Route
              path="ourArtist/:id/next-biography"
              element={ <RequireAuth><OurArtistBiography /></RequireAuth>}
            />
            <Route
              path="ourArtist/:id/next-biography/:idU"
              element={ <RequireAuth><OurArtistBiographyAdd /></RequireAuth>}
            />
            <Route path="mediaCenter" element={<RequireAuth><MediaCenter /> </RequireAuth>} />
            <Route path="/mediaCenter/:id" element={<RequireAuth><AddMediaCenter /></RequireAuth>} />
            <Route path="information" element={ <RequireAuth><Information /></RequireAuth>} />
            <Route path="information/add" element={<RequireAuth><InformationAdd /></RequireAuth>} />
            <Route path="information/:id" element={<RequireAuth><ImagesHome /></RequireAuth>} />
            <Route path="Change-Password" element={<RequireAuth><ChangePassword /></RequireAuth>} />
            <Route path="Messages" element={<RequireAuth><Messages /></RequireAuth>} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default App;
