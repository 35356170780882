function Title(props) {
  return (
    <>
      <p className="text-3xl text-center text-Brown font-semibold">
        {props.title}
      </p>
    </>
  );
}
export default Title;
