import { Link } from "react-router-dom";
import { Container, Add, CardAll, Loading } from "../../components";
import ReactPaginate from "react-paginate";
import { useFETCH } from "../../APIs/useMyAPI";

function OurArtist() {
  const { data, isLoading, handlePageClick, deleteItem } = useFETCH("artists");
  return (
    <Container>
      <div className="flex flex-wrap justify-center gap-3 ">
        {data?.data.data.data == 0 ? (
          <div className="font-semibold flex items-center h-[50vh] text-2xl">
            There are no items to show
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <Loading />
        ) : (
          data?.data.data.data.map((e) => (
            <CardAll
              key={e.id}
              delete={() => deleteItem(e)}
              update={`/ourArtist/${e.id}`}
            >
              <Link to={`/ourArtist/${e.id}/next-press`}>
                <div className="flex font-semibold w-[230px] max-w-[230px] bg-white">
                  <p className="font-semibold px-2 text-xl  ">{e.name}</p>
                  <p className="font-semibold px-2 text-xl  ">{e.uid}</p>
                </div>
              </Link>
            </CardAll>
          ))
        )}
      </div>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <div className="py-8 ">
        <Add link={"/ourArtist/add"}>Add Artists</Add>
      </div>
    </Container>
  );
}
export default OurArtist;
