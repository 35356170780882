import { useEffect } from "react";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { Back, Loading } from "../../components";
import Title from "../../components/Button/Title";

function AboutUsAdd() {
  const { formData, setFormData, handleChange, loading, error, handleSubmit } =
    usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit("site-info/about-us");
  };
  const { data } = useFETCH(`site-info/about-us`);
  useEffect(() => {
      setFormData({
        about_us_description: data?.data.data.about_us_description,
        about_us_mission: data?.data.data.about_us_mission,
        about_us_vision: data?.data.data.about_us_vision,
      });
  }, [data?.data.data]);
  return (
    <>
      {loading ? <Loading /> : ""}
      <div className="w-[50%] max-sm:w-[75%] mx-auto flex flex-col gap-5 mb-28 max-[380px]:mb-44">
        <Title title="About Us" />

        <textarea
          type="text"
          onChange={handleChange}
          value={formData.about_us_description}
          name="about_us_description"
          className="text-Gray font-semibold px-2 outline-none border-2 border-Brown rounded-xl h-[150px] w-[100%]"
          placeholder="Add Description (About Us)"
        />
        <textarea
          type="text"
          value={formData.about_us_mission}
          onChange={handleChange}
          name="about_us_mission"
          className="text-Gray font-semibold px-2 outline-none border-2 border-Brown rounded-xl h-[150px] w-[100%]"
          placeholder={"Add Mission"}
        />
        <textarea
          type="text"
          value={formData.about_us_vision}
          onChange={handleChange}
          name="about_us_vision"
          className="text-Gray font-semibold px-2 outline-none border-2 border-Brown rounded-xl h-[150px] w-[100%]"
          placeholder={"Add Vision"}
        />
        <div className="text-red-500 font-semibold ">{error}</div>
      </div>

      <Back onClick={handleFormSubmit} link={"/aboutUs"}>
        Save
      </Back>
    </>
  );
}
export default AboutUsAdd;
