import { Container, Add, Border, Loading } from "../../components";
import Img1 from "../../images/Path 2281.png";
import Img2 from "../../images/edit (2).png";
import { Link } from "react-router-dom";
import { useState } from "react";
import { fileUrl, handleClick, useFETCH } from "../../APIs/useMyAPI";
import ReactPaginate from "react-paginate";

function Exhibition() {
  const { data, isLoading, handlePageClick, deleteItem } =
    useFETCH("exhibitions");
  return (
    <Container>
      <div className="flex justify-center flex-wrap gap-5 mb-24">
        {data?.data.data.data == 0 ? (
          <div className="font-semibold flex items-center h-[50vh] text-2xl">
            There are no items to show
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <Loading />
        ) : (
          data?.data.data.data.map((e) => (
            <Border key={e.id}>
              <Ddd link={`/exhibition/${e.id}`} delete={() => deleteItem(e)} />
              <div className="w-[360px] max-sm:w-[100%] max-lg:mx-auto mx-auto bg-white p-4">
                <img
                  onClick={handleClick}
                  src={fileUrl + e.image}
                  alt=""
                  className="w-full h-[300px] mx-auto object-fill rounded-2xl"
                />
                <div className="flex justify-start flex-col flex-wrap py-3">
                  <div className="flex items-center text-xl font-semibold">
                    <p className="text-Brown font-bold text-xl px-2">name:</p>
                    {e.name}
                  </div>
                  <div className="flex items-center text-xl font-semibold">
                    <p className="text-Brown font-bold text-xl px-2">Price:</p>
                    {e.price === "undefined" || !e.price
                      ? " لا يوجد بيانات"
                      : e.price}
                  </div>
                  <div className="flex items-center text-xl font-semibold">
                    <p className="text-Brown font-bold text-xl px-2">Type:</p>

                    {e.type === "undefined" || !e.type
                      ? " لا يوجد بيانات"
                      : e.type}
                  </div>
                  <div className="flex items-center text-xl font-semibold">
                    <p className="text-Brown font-bold text-xl px-2">
                      Description:
                    </p>
                    {e.description === "undefined" || !e.description
                      ? " لا يوجد بيانات"
                      : e.description}
                  </div>
                </div>
              </div>
            </Border>
          ))
        )}
      </div>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link={"/exhibition/addExhibition"}>Add New Event</Add>
    </Container>
  );
}
export default Exhibition;

const Ddd = (props) => {
  const [sure, setSure] = useState(false);
  return (
    <>
      <div
        onClick={() => setSure(false)}
        className={`${
          sure ? "" : "hidden"
        } fixed w-full h-full top-0 left-0 popup z-40 flex justify-center items-center`}
      >
        <div className="bg-white z-50 rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
          <p className="font-semibold text-3xl text-center py-7">
            Are you sure to delete the item ?
          </p>
          <div className="flex items-end m-5">
            <button
              onClick={props.delete}
              className=" border px-10 border-Brown bg-Brown text-white font-semibold  p-3 rounded-xl"
            >
              Yes
            </button>
            <button
              onClick={() => setSure(false)}
              className=" border px-10 border-Brown text-Brown bg-white font-semibold  p-3 rounded-xl ml-5"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-start p-4 space-x-4 ">
        <img
          src={Img1}
          alt=""
          onClick={() => setSure(true)}
          className="cursor-pointer"
        />
        <Link to={props.link}>
          <img src={Img2} alt="" className="cursor-pointer" />
        </Link>
      </div>
    </>
  );
};
