import { useParams } from "react-router-dom";
import { AddPlus, CardAll, Container, Loading } from "../../components";
import SecondNavbar from "../../components/Navbar/SecondNavbar";
import { fileUrl, handleClick, useFETCH } from "../../APIs/useMyAPI";

function OurArtistPress() {
  const { id } = useParams();

  const { data, isLoading, deleteItem } = useFETCH(
    `artists/${id}/presses`,
    "artists/presses"
  );
  return (
    <Container>
      <SecondNavbar />
      <div>
        <div className="flex flex-wrap justify-center gap-5 mb-14 ">
          {" "}
          {data?.data.data == 0 ? (
            <div className="font-semibold flex items-center h-[50vh] text-2xl">
              There are no items to show
            </div>
          ) : (
            ""
          )}
          {isLoading ? (
            <Loading />
          ) : (
            data?.data.data.map((e) => (
              <CardAll
                key={e.id}
                delete={() => deleteItem(e)}
                update={`/ourArtist/${id}/next-press/${e.id}`}
              >
                <img
                  onClick={handleClick}
                  src={fileUrl + e.image}
                  alt=""
                  className="w-[360px] h-[250px] rounded-2xl"
                />
                <p className="py-2 text-center text-2xl text-Brown">{e.name}</p>
                <p className="py-2 text-xl text-center w-[360px]">
                  {e.description}
                </p>
              </CardAll>
            ))
          )}
        </div>
      </div>
      <AddPlus link={`/ourArtist/${id}/next-press/add`} />
    </Container>
  );
}
export default OurArtistPress;
