const ButtonApp = (props) => {
  return (
    <>
      <button onClick={props.onClick} className=" border border-Brown text-Brown font-semibold bg-white p-3  rounded-xl hover:bg-Brown hover:text-white transition-all">
        {props.children}
      </button>
    </>
  );
};

export default ButtonApp;
