import { Container, Add, Border, Loading, CardAll } from "../../components";

import { fileUrl, handleClick, useFETCH } from "../../APIs/useMyAPI";
import ReactPaginate from "react-paginate";
function MediaCenter() {
  const { data, isLoading, handlePageClick, deleteItem } =
    useFETCH("media-center/files");
  return (
    <Container>
      <div className="flex justify-center flex-wrap gap-3  ">
        {data?.data.data.data == 0 ? (
          <div className="font-semibold flex items-center h-[50vh] text-2xl">
            There are no items to show
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <Loading />
        ) : (
          data?.data.data.data.map((e) => (
            <CardAll
              key={e.id}
              delete={() => deleteItem(e)}
              update={`/mediaCenter/${e.id}`}
            >
              {e.type === "image" && (
                <img
                  onClick={handleClick}
                  className="w-[300px] h-[260px] mx-auto rounded-2xl"
                  src={fileUrl + e.file}
                  alt=""
                />
              )}
              {e.type === "video" && (
                <video
                  controls
                  className="w-[300px] h-[260px] object-fill rounded-lg"
                >
                  <source src={fileUrl + e.file} type="video/mp4" />
                </video>
              )}
            </CardAll>
          ))
        )}
      </div>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link={"/mediaCenter/addMediaCenter"}>Add Media Center</Add>
    </Container>
  );
}
export default MediaCenter;
