import { useParams } from "react-router-dom";
import { fileUrl, handleClick, useFETCH } from "../../APIs/useMyAPI";
import { AddPlus, CardAll, Container, Loading } from "../../components";
import SecondNavbar from "../../components/Navbar/SecondNavbar";
import ReactPaginate from "react-paginate";

function OurArtistPastExhibition() {
  const { id } = useParams();

  const { data, isLoading, handlePageClick, deleteItem } = useFETCH(
    `artists/${id}/_/images`,
    "artists/files"
  );
  return (
    <Container>
      <SecondNavbar />
      <div className="flex flex-wrap justify-center gap-5 mb-14">
        {data?.data.data.data == 0 ? (
          <div className="font-semibold flex items-center h-[50vh] text-2xl">
            There are no items to show
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <Loading />
        ) : (
          data?.data.data.data?.map((e) => (
            <div
              key={e.id}
              className="flex flex-col justify-center border-[3px] w-[30%] max-lg:w-[48%] max-sm:w-[80%] border-Brown rounded-2xl fle"
            >
              <CardAll
                delete={() => deleteItem(e)}
                update={`/ourArtist/${id}/next-pastExhibition/${e.id}`}
              >
                <img
                  onClick={handleClick}
                  src={fileUrl + e.file}
                  alt=""
                  className="w-full h-[250px]  rounded-2xl"
                />
              </CardAll>
            </div>
          ))
        )}
      </div>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <AddPlus link={`/ourArtist/${id}/next-pastExhibition/add`} />
    </Container>
  );
}
export default OurArtistPastExhibition;
