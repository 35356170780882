import React, { useState } from "react";
import { useFETCH } from "../../APIs/useMyAPI";
import ReactPaginate from "react-paginate";
import { Container, Loading } from "../../components";
import Img1 from "../../images/Path 2281.png";
const Messages = () => {
  const { data, isLoading, handlePageClick, deleteItem } = useFETCH("messages");
  return (
    <Container>
      <div className="flex flex-wrap justify-center gap-3">
        {data?.data.data.data == 0 ? (
          <div className="font-semibold flex items-center h-[50vh] text-2xl">
            There are no items to show
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <Loading />
        ) : (
          data?.data.data.data.map((e) => (
            <div
              key={e.id}
              className="flex flex-col bg-white border-[2px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[360px] max-w-[360px]"
            >
              <Ddd delete={() => deleteItem(e)} />
              <div className="text-xl text-start pb-3 pt-6">
                <span className="text-Brown font-bold text-xl pl-2 pr-3">
                  Name :
                </span>
                {e.name}
              </div>
              <div className="text-xl text-start pb-3">
                <span className="text-Brown font-bold text-xl pl-2 pr-3">
                  Phone:
                </span>
                {e.phone_number}
              </div>
              <div className="text-xl text-start pb-3">
                <span className="text-Brown font-bold text-xl pl-2 pr-3">
                  Message :
                </span>
                {e.message}
              </div>
            </div>
          ))
        )}
      </div>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
    </Container>
  );
};

export default Messages;

const Ddd = (props) => {
  const [sure, setSure] = useState(false);
  return (
    <>
      <div
        onClick={() => setSure(false)}
        className={`${
          sure ? "" : "hidden"
        } fixed w-full h-full top-0 left-0 popup z-40 flex justify-center items-center`}
      >
        <div className="bg-white z-50 rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
          <p className="font-semibold text-3xl text-center py-7">
            Are you sure to delete the item ?
          </p>
          <div className="flex items-end m-5">
            <button
              onClick={props.delete}
              className=" border px-10 border-Brown bg-Brown text-white font-semibold  p-3 rounded-xl"
            >
              Yes
            </button>
            <button
              onClick={() => setSure(false)}
              className=" border px-10 border-Brown text-Brown bg-white font-semibold  p-3 rounded-xl ml-5"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-start space-x-4 ">
        <img
          src={Img1}
          alt=""
          onClick={() => setSure(true)}
          className="cursor-pointer"
        />
      </div>
    </>
  );
};
