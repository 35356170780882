import { useParams } from "react-router-dom";
import { Back, Container, Input, Loading } from "../../components";
import AddImages from "../../components/AddImage/AddImages";
import { fileUrl, handleClick, useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useEffect } from "react";

function OurArtistPressAdd() {
  const { id, idU } = useParams();
  const {
    formData,
    setFormData,
    handleChange,
    loading,
    handleSubmit,
    img,
    error,
    setImg,
  } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(
      isNaN(idU) ? `artists/${id}/presses` : `artists/presses/${idU}`
    );
  };
  const { data } = useFETCH(`artists/presses/${idU}`);
  let dataUp = data?.data.data;

  useEffect(() => {
    !isNaN(id) &&
      setFormData({
        name: dataUp?.name,
        description: dataUp?.description,
      });
  }, [dataUp]);
  return (
    <Container>
      {loading ? <Loading /> : ""}
      <div className="flex flex-col max-[380px]:mb-32">
        <div className="w-[50%] max-sm:w-[90%] mx-auto mt-8 space-y-2">
          <Input
            name="name"
            value={formData.name}
            onChange={handleChange}
            tpye="text"
            title="Add Artist Name"
          />
          <textarea
            type="text"
            onChange={handleChange}
            value={formData.description}
            name="description"
            className="text-Gray font-semibold px-2 outline-none border-2 border-Brown rounded-xl h-[150px] w-[100%]"
            placeholder="Description"
          />
        </div>

        <div className="mt-8">
          <AddImages
            onChange={handleChange}
            name="image"
            image={"Add Photo"}
          ></AddImages>
        </div>
        <div className="flex justify-center my-4">
          {img && (
            <div className="relative">
              <div
                onClick={() => {
                  setImg("");
                  setFormData("");
                }}
                className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
              >
                X
              </div>
              <img
                onClick={handleClick}
                className="w-[300px] h-[220px] mx-auto rounded-2xl"
                src={img}
                alt=""
              />
            </div>
          )}
          {isNaN(idU) ? (
            ""
          ) : (
            <div className="relative mx-4">
              <img
                onClick={handleClick}
                className="w-[300px] h-[220px] mx-auto rounded-2xl mb-6"
                src={fileUrl + dataUp?.image}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
      <div className="text-red-500 font-semibold ">{error}</div>
      <div className="py-8 ">
        <Back onClick={handleFormSubmit}>Add</Back>
      </div>
    </Container>
  );
}
export default OurArtistPressAdd;
