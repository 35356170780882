import { useParams } from "react-router-dom";
import { Back, Container, Input, Loading } from "../../components";
import { handleClick, useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useEffect } from "react";
import AddImages from "../../components/AddImage/AddImages";

function OurArtistAdd() {
  const { id } = useParams();
  const {
    formData,
    setFormData,
    handleChange,
    loading,
    error,
    handleSubmit,
    img,
    setImg,
  } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(isNaN(id) ? "artists" : `artists/${id}`);
  };
  const { data } = useFETCH(`artists/${id}`);
  let dataUp = data?.data.data;
  useEffect(() => {
    !isNaN(id) &&
      setFormData({
        name: dataUp?.name,
        biography_text: dataUp?.biography_text,
        uid: dataUp?.uid,
      });
  }, [dataUp]);

  return (
    <div>
      {loading ? <Loading /> : ""}
      <Container>
        <div className="w-[50%] max-sm:w-[90%] mx-auto mt-8 space-y-5">
          <Input
            name="name"
            value={formData.name}
            onChange={handleChange}
            tpye="text"
            title="Add Artist Name"
          />
          <Input
            name="uid"
            value={formData.uid}
            onChange={handleChange}
            tpye="text"
            title="id"
          />
          {isNaN(id) ? (
            <textarea
              type="text"
              onChange={handleChange}
              value={formData.biography_text}
              name="biography_text"
              className="text-Gray font-semibold px-2 outline-none border-2 border-Brown rounded-xl h-[150px] w-[100%]"
              placeholder="Add Description "
            />
          ) : (
            ""
          )}
        </div>
        {isNaN(id) ? (
          <>
            <div className="mt-8">
              <AddImages
                onChange={handleChange}
                name="biography_image"
                image={"Add Photo"}
              ></AddImages>
            </div>
            <div className="flex justify-center my-4">
              {img && (
                <div className="relative">
                  <div
                    onClick={() => {
                      setImg("");
                      setFormData("");
                    }}
                    className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                  >
                    X
                  </div>
                  <img
                    onClick={handleClick}
                    className="w-[300px] h-[220px] mx-auto rounded-2xl"
                    src={img}
                    alt=""
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
        <div className="text-red-500 font-semibold ">{error}</div>
        <div className="py-8 ">
          <Back onClick={handleFormSubmit}>Add</Back>
        </div>
      </Container>
    </div>
  );
}
export default OurArtistAdd;
