import React from "react";
import { Link } from "react-router-dom";

const Add = (props) => {
  return (
    <div className="fixed z-30 bottom-8 right-16 max-sm:right-2 max-sm:bottom-4 overflow-hidden">
      <Link to={props.link || ""}>
        <div
         className="bg-Brown cursor-pointer text-white font-semibold text-xl rounded-xl py-2 px-10"
          onClick={props.click}
        >
          {props.children}
        </div>
      </Link>

    </div>
  );
};

export default Add;
