import { useFETCH } from "../../APIs/useMyAPI";
import { Container, Add, Loading } from "../../components";

function AboutUs() {

  const { data, isLoading } = useFETCH("site-info/about-us")
  return (
    <>
    {
      isLoading ? <Loading/> : null
    }
      <Container>
        <div className="flex flex-wrap justify-center gap-5 mb-14 ">
          <div className="p-2 border-[3px] w-[30%] text-xl max-lg:w-[48%] max-sm:w-full border-Brown rounded-2xl">
            <div className="text-Brown font-bold text-xl pl-2 pr-3">
              Description (About Us): 
            </div>
            <span>
              {data?.data.data.about_us_description}
            </span>
          </div>

          <div className="p-2 border-[3px] w-[30%] text-xl max-lg:w-[48%] max-sm:w-full border-Brown rounded-2xl">
            <span className="text-Brown font-bold text-xl pl-2 pr-3">
              Mission :
            </span>
            <span>
              {data?.data.data.about_us_mission}
            </span>
          </div>

          <div className="p-2 border-[3px] w-[30%] text-xl max-lg:w-[48%] max-sm:w-full border-Brown rounded-2xl">
            <span className="text-Brown font-bold text-xl pl-2 pr-3">
              Vision :
            </span>
            <span>
              {data?.data.data.about_us_vision}
            </span>
          </div>
        </div>
        <Add link={"/aboutUs/add"}>Edit</Add>
      </Container>
    </>
  );
}
export default AboutUs;
