function Back(props) {
  return (
    <>
      <div className="fixed z-10 bottom-8 right-16 max-sm:right-2 max-sm:bottom-4 overflow-hidden">
        <div className="flex gap-5 flex-wrap max-sm:justify-end">
          {props.onClick && (
            <div
              className="bg-Brown cursor-pointer text-white font-semibold text-xl rounded-xl py-2 px-10"
              onClick={props.onClick}
            >
              {props.children}
            </div>
          )}

          <div
            onClick={() => window.history.go(-1)}
            className="bg-Brown cursor-pointer text-white font-semibold text-xl rounded-xl py-2 px-10"
          >
            Back
          </div>
        </div>
      </div>
    </>
  );
}
export default Back;
