import { Back, Container, Loading } from "../../components";
import addIcon from "../../images/+.png";
import { fileUrl, useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";
function OurArtistVideoAdd() {
  const { id, idU } = useParams();
  const {
    setFormData,
    handleChange,
    loading,
    handleSubmit,
    img,
    error,
    setImg,
  } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(
      isNaN(idU) ? `artists/${id}/_/videos` : `artists/files/${idU}`
    );
  };
  const { data } = useFETCH(`artists/files/${idU}`);
  let dataUp = data?.data.data;
  return (
    <div>
      {loading ? <Loading /> : ""}
      <Container>
        <div className="min-h-[80px] w-[50%] bg-white max-lg:w-[80%] border-2 font-semibold border-dashed border-Brown mx-auto rounded-lg mt-16">
          <div
            className={`${
              img ? "hidden " : "flex items-center justify-center"
            }`}
          >
            <input
              id="OurArtist4"
              type="file"
              name="file"
              onChange={handleChange}
              accept="video/mp4,video/x-m4v,video/*"
              style={{ display: "none" }}
            />
            <button
              onClick={() => document.getElementById("OurArtist4").click()}
              className="block w-full h-full"
            >
              <di className="flex items-center justify-between mx-5 py-3">
                <p className="text-gray-300 text-xl">add video</p>
                <img src={addIcon} alt="" className="w-8" />
              </di>
            </button>
          </div>
        </div>

        <div className="relative flex justify-center mt-6 mb-40">
          {img && (
            <>
              <div
                onClick={() => {
                  setFormData("");
                  setImg("");
                }}
                className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -ml-[260px] z-10 flex cursor-pointer items-center justify-center "
              >
                X
              </div>
              <video
                controls
                className="w-[280px] h-[250px] object-fill rounded-lg"
              >
                <source src={img} />
              </video>
            </>
          )}

          {isNaN(idU) ? (
            ""
          ) : (
            <div className="relative mx-4">
           
              <video
                controls
                className="w-[280px] h-[250px] object-fill rounded-lg"
              >
                <source src={fileUrl + dataUp?.file} />
              </video>
            </div>
          )}
        </div>
        <div className="text-red-500 font-semibold ">{error}</div>
        <Back onClick={handleFormSubmit}>Save</Back>
      </Container>
    </div>
  );
}
export default OurArtistVideoAdd;
