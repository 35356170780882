import ImageIcon from "../../images/ImageIcon.png";

function AddImages(props) {
  return (
    <div className="border-dashed border-[2px] border-Brown rounded-lg bg-white w-[50%] max-sm:w-[80%] mx-auto">
      <div className="flex items-center justify-center">
        <input
          id="files"
          type="file"
          name={props.name}
          onChange={props.onChange}
          className="hidden"
          accept="image/*"
          multiple
        />
        <div
          className="flex items-center justify-between w-full px-5 py-3"
          onClick={() => document.getElementById("files").click()}
        >
          <p className="text-gray-400 font-semibold">{props.image}</p>
          <img src={ImageIcon} alt="" className="w-10" />
          {props.child}
        </div>
      </div>
      <div className="flex gap-3 flex-wrap">
        <div>{props.children}</div>
      </div>
    </div>
  );
}
export default AddImages;
