import { useEffect, useState } from "react";
import { Container, Input, Border, Back, Loading } from "../../components";
import AddImages from "../../components/AddImage/AddImages";
import { fileUrl, handleClick, useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";
import ImageIcon from "../../images/ImageIcon.png";
function AddEvents() {
  const { id } = useParams();
  const {
    formData,
    setFormData,
    handleChange,
    handleChangeArray,
    loading,
    error,
    handleSubmit,
    img,
    setImg,
    imgs,
    setImgs,
    setImgss,
  } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(isNaN(id) ? "events" : `events/${id}`);
  };
  const handleFormSubmitImages = (event) => {
    event.preventDefault();
    handleSubmit(`events/${id}/images`);
  };
  const { data } = useFETCH(`events/${id}`);
  let dataUp = data?.data.data;
  useEffect(() => {
    !isNaN(id) &&
      setFormData({
        prefix: dataUp?.prefix,
        name: dataUp?.name,
        invitation: dataUp?.invitation,
        invitation_name: dataUp?.invitation_name,
        date: dataUp?.date,
        time: dataUp?.time,
        location: dataUp?.location,
        phones_numbers: dataUp?.phones_numbers,
        date_time: dataUp?.date_time,
        image_mode: dataUp?.image_mode,
      });
  }, [dataUp]);

  const { data: data2, deleteItem } = useFETCH(
    `events/${id}/images`,
    "events/images"
  );
  return (
    <Container>
      {loading ? <Loading /> : ""}
      <div className="flex flex-col mb-40">
        <div className="w-[50%] max-sm:w-[80%] mx-auto mt-8 space-y-5">
          <Input
            name="prefix"
            value={formData.prefix}
            onChange={handleChange}
            type="text"
            title="Add Prev text"
          />
          <Input
            name="name"
            value={formData.name}
            onChange={handleChange}
            type="text"
            title="Add Event Name"
          />
          <Input
            name="invitation"
            value={formData.invitation}
            onChange={handleChange}
            type="text"
            title="Add Invitation"
          />
          <Input
            name="invitation_name"
            value={formData.invitation_name}
            onChange={handleChange}
            type="text"
            title="Add Invitation Name"
          />
          <Input
            name="date"
            value={formData.date}
            onChange={handleChange}
            type="text"
            title="Add Date"
          />
          <Input
            name="time"
            value={formData.time}
            onChange={handleChange}
            type="text"
            title="Add Time"
          />
          <Input
            name="location"
            value={formData.location}
            onChange={handleChange}
            type="text"
            title="Add Location"
          />
          <Input
            name="phones_numbers"
            value={formData.phones_numbers}
            onChange={handleChange}
            type="text"
            title="Add Numbers"
          />
          <Input
            name="date_time"
            value={formData.date_time}
            onChange={handleChange}
            type="datetime-local"
            title="date"
          />
        </div>
        <select
          
          className="sm:w-1/2 mx-auto mt-5 text-Gray font-semibold px-2 outline-none border-2 border-Brown rounded-xl py-5 w-full block text-center"
          name="image_mode"
          value={formData.image_mode}
          onChange={handleChange}
        >
          <option value=""></option>
          <option value="360">image 360</option>
          <option value="NORMAL">normal image</option>
        </select>
        <div className="mt-8">
          <AddImages
            onChange={handleChange}
            name="_360_image"
            image={"Add Photo 360"}
          ></AddImages>
        </div>
        <div className="flex justify-center my-4">
          {img && (
            <div className="relative">
              <div
                onClick={() => {
                  setImg("");
                  setFormData("");
                }}
                className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
              >
                X
              </div>
              <img
                onClick={handleClick}
                className="w-[300px] h-[220px] mx-auto rounded-2xl"
                src={img}
                alt=""
              />
            </div>
          )}
        </div>
        {isNaN(id) ? (
          ""
        ) : (
          <div className="relative">
            <img
              onClick={handleClick}
              className="w-[300px] h-[220px] mx-auto rounded-2xl mb-6"
              src={fileUrl + dataUp?._360_image}
              alt=""
            />
          </div>
        )}
        <div className="">
          <div className="border-dashed border-[2px] border-Brown rounded-lg bg-white w-[50%] max-sm:w-[80%] mx-auto">
            <div className="flex items-center justify-center">
              <input
                id="filess"
                type="file"
                name="images[]"
                onChange={handleChangeArray}
                className="hidden"
                accept="image/*"
                multiple
              />
              <div
                className="flex items-center justify-between w-full px-5 py-3"
                onClick={() => document.getElementById("filess").click()}
              >
                <p className="text-gray-400 font-semibold">Add Photos</p>
                <img src={ImageIcon} alt="" className="w-10" />
              </div>
            </div>
          </div>
          {isNaN(id) ? (
            ""
          ) : (
            <div
              className="bg-Brown w-fit cursor-pointer text-white font-semibold text-xl rounded-xl py-2 px-10 "
              onClick={handleFormSubmitImages}
            >
              Add Images
            </div>
          )}
          {imgs && (
            <div className={`w-full my-5 ${imgs == 0 ? "hidden" : ""}`}>
              <Border>
                <div className="flex flex-wrap p-5 w-full gap-2">
                  {imgs.map((imageUrl) => (
                    <div className="relative">
                      <div
                        onClick={(i) => {
                          let total = [...imgs];
                          total.splice(i, 1);
                          setImgs(total);
                          setImgss(total);
                        }}
                        className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                      >
                        X
                      </div>
                      <img
                        onClick={handleClick}
                        className="w-[150px] h-[150px] mx-auto rounded-2xl p-"
                        key={imageUrl}
                        src={imageUrl}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              </Border>
            </div>
          )}
        </div>
      </div>
      <div className="text-red-500 font-semibold ">{error}</div>
      <Back onClick={handleFormSubmit}>Save</Back>

      {isNaN(id) ? (
        ""
      ) : (
        <Border>
          <div className="flex flex-wrap gap-2">
            {data2?.data.data.map((e) => (
              <div
                key={e.id}
                className="relative w-[150px] h-[150px] mx-auto my-4"
              >
                <div
                  onClick={() => deleteItem(e)}
                  className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                >
                  X
                </div>
                <img
                  onClick={handleClick}
                  className="w-[150px] h-[150px]  mx-auto rounded-2xl"
                  src={fileUrl + e.image}
                  alt=""
                />
              </div>
            ))}
          </div>
        </Border>
      )}
    </Container>
  );
}
export default AddEvents;
