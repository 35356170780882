import { useEffect, useRef, useState } from "react";
import { Back, Border, Container, Loading } from "../../components";
import AddImages from "../../components/AddImage/AddImages";
import addIcon from "../../images/+.png";
import { fileUrl, handleClick, useFETCH, usePOST } from "../../APIs/useMyAPI";
import JoditEditor from "jodit-react";

function InformationAdd() {
  const editor = useRef(null);
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [active, setActive] = useState("image");
  const { loading, error, handleSubmit, setFormData } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit("site-info/home");
  };
  const { data } = useFETCH(`site-info/home`);
  let dataUp = data?.data.data;
  useEffect(() => {
    setFormData({
      home_description: content1 || dataUp?.home_description,
      home_about_us: content2 || dataUp?.home_about_us,
      location: content3 || dataUp?.location,
    });
  }, [content1, content2, content3]);
  const {
    handleChange: handleChange2,
    loading: loading2,
    error: error2,
    handleSubmit: handleSubmit2,
    setFormData: setFormData2,
    img: img2,
    setImg: setImg2,
    handleChangeArray: handleChangeArray2,
  } = usePOST({});
  const handleFormSubmit2 = (event) => {
    event.preventDefault();
    handleSubmit2("home/slider/files");
  };
  const { data: data2, deleteItem, isLoading } = useFETCH(`home/slider/files`);
  return (
    <>
      <Container>
        {loading ? <Loading /> : ""}
        {loading2 ? <Loading /> : ""}
        <div className="w-[50%] max-sm:w-[80%] mx-auto mt-8  space-y-5">
          <h1 className="flex items-center font-semibold text-Brown justify-start m-2">
            Home Description and Title:
          </h1>
          <JoditEditor
            ref={editor}
            value={dataUp?.home_description}
            onChange={(newContent) => setContent1(newContent)}
          />
          <h1 className="flex items-center font-semibold text-Brown justify-start m-2">
            Home About Us :
          </h1>
          <JoditEditor
            ref={editor}
            value={dataUp?.home_about_us}
            onChange={(newContent) => setContent2(newContent)}
          />
          <h1 className="flex items-center font-semibold text-Brown justify-start m-2">
            Location :
          </h1>
          <JoditEditor
            ref={editor}
            value={dataUp?.location}
            onChange={(newContent) => setContent3(newContent)}
          />
        </div>

        <div className="text-red-500 font-semibold ">{error}</div>
        <div className="flex gap-5 flex-wrap justify-end ">
          <div
            className="bg-Brown text-white font-semibold text-xl rounded-xl py-2 px-10 cursor-pointer"
            onClick={handleFormSubmit}
          >
            save
          </div>
        </div>
      </Container>
      {/* ---------------------------------------------------------------------------- */}
      <Container>
        <div className="flex space-x-3 justify-center">
          <div className="flex items-center">
            <input
              type="radio"
              value="image"
              onChange={handleChange2}
              name="type"
              onClick={() => {
                setImg2("");
                setFormData2("");
                setActive("image");
              }}
              className="h-5 w-5 mx-2 "
            />{" "}
            <span>Photo</span>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              value="video"
              onChange={handleChange2}
              name="type"
              onClick={() => {
                setImg2("");
                setFormData2("");
                setActive("video");
              }}
              className="h-5 w-5  mx-2 "
            />
            <span>Video</span>
          </div>
        </div>
        {active === "image" ? (
          <div className="mt-8">
            <AddImages
              onChange={handleChange2}
              name="file"
              image={"Add Photos"}
            ></AddImages>
            {img2 && (
              <div className={`w-full  mb-5 mx- ${img2 == 0 ? "hidden" : ""}`}>
                <div img="">
                  <div className="relative w-[150px] h-[150px] mx-auto my-4">
                    <div
                      onClick={() => {
                        setImg2("");
                        setFormData2("");
                      }}
                      className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                    >
                      X
                    </div>
                    <img
                      onClick={handleClick}
                      className="w-[150px] h-[150px]  mx-auto  rounded-2xl"
                      src={img2}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {/* ---------------------------------------------------------------------------- */}
        {active === "video" ? (
          <>
            <div className="min-h-[80px] w-[50%] bg-white max-sm:w-[80%] border-2 font-semibold border-dashed border-Brown mx-auto rounded-lg mt-8 max-sm:mb-40">
              <div
                className={`${
                  img2 ? "hidden " : "flex items-center justify-center"
                }`}
              >
                <input
                  id="OurArtist4"
                  type="file"
                  name="file"
                  onChange={handleChange2}
                  className="hidden"
                  accept="video/mp4,video/x-m4v,video/*"
                  multiple
                />
                <button
                  onClick={() => document.getElementById("OurArtist4").click()}
                  className="block w-full h-full"
                >
                  <di className="flex items-center justify-between mx-5 py-3">
                    <p className="text-gray-300 text-xl">add video</p>
                    <img src={addIcon} alt="" className="w-8" />
                  </di>
                </button>
              </div>
            </div>
            {img2 && (
              <div className="relative flex justify-center mt-6 mb-40">
                <div
                  onClick={() => {
                    setImg2("");
                    setFormData2("");
                  }}
                  className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -ml-[260px] z-10 flex cursor-pointer items-center justify-center "
                >
                  X
                </div>
                <video
                  controls
                  className="w-[280px] h-[250px] object-fill rounded-lg"
                >
                  <source src={img2} type="video/mp4" />
                </video>
              </div>
            )}
          </>
        ) : (
          ""
        )}
        {isLoading ? <Loading /> : ""}
        <div className="text-red-500 font-semibold ">{error2}</div>
        <Back />
        <div className="flex gap-5 flex-wrap justify-end ">
          <div
            className="bg-Brown text-white font-semibold text-xl rounded-xl py-2 px-10 cursor-pointer"
            onClick={handleFormSubmit2}
          >
            save
          </div>
        </div>
        <div className="my-2">
          <Border>
            <div className="flex flex-wrap gap-2">
              {data2?.data.data.map((e) => (
                <div
                  key={e.id}
                  className="relative w-[150px] h-[150px] mx-auto my-4"
                >
                  <div
                    onClick={() => {
                      deleteItem(e);
                    }}
                    className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                  >
                    X
                  </div>
                  <>
                    {e.type === "image" ? (
                      <img
                        onClick={handleClick}
                        className="w-[150px] h-[150px]  mx-auto rounded-2xl"
                        src={fileUrl + e.file}
                        alt=""
                      />
                    ) : (
                      <video
                        controls
                        className="w-[150px] h-[150px] object-cover mx-auto rounded-2xl"
                      >
                        <source src={fileUrl + e.file} type="video/mp4" />
                      </video>
                    )}
                  </>
                </div>
              ))}
            </div>
          </Border>
        </div>
      </Container>
    </>
  );
}
export default InformationAdd;
