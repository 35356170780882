import { NavLink, useParams } from "react-router-dom";
import Container from "../Container/Container";
import "../SideBar/SideBar.css";

function SecondNavbar() {
  const { id } = useParams();
  return (
    <div>
      <Container>
        <nav className="flex items-center justify-between border-l-4 border-2 bg-white shadow-md overflow-y-auto w-full ">
          <NavLink
            className="border-r-2 border-Brown px-8 py-6 text-xl w-full text-center"
            to={`/ourArtist/${id}/next-press`}
          >
            Press
          </NavLink>
          <NavLink
            className="border-r-2 border-Brown px-8 py-6 text-xl w-full text-center"
            to={`/ourArtist/${id}/next-video`}
          >
            Videos
          </NavLink>
          <NavLink
            className="border-r-2 border-Brown px-8 py-6 text-xl w-[100%] text-center"
            to={`/ourArtist/${id}/next-pastExhibition`}
          >
            <div>
              <span className="inline pr-3">Past</span>
              <span className="inline">Exhibitions</span>
            </div>
          </NavLink>
          <NavLink
            className="px-8 py-6 text-xl w-full text-center"
            to={`/ourArtist/${id}/next-biography`}
          >
            Biography
          </NavLink>
        </nav>
      </Container>
    </div>
  );
}
export default SecondNavbar;
