import { Container, Loading } from "../../components/index";
import { MdOutlineLanguage, MdOutlineMailOutline } from "react-icons/md";
import { BiLinkAlt, BiSave } from "react-icons/bi";
import {
  AiFillYoutube,
  AiOutlineWhatsApp,
  AiTwotonePhone,
} from "react-icons/ai";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { useEffect, useState } from "react";
import { baseUrl, useFETCH } from "../../APIs/useMyAPI";
import axios from "axios";

const ContactUsTwo = () => {
  const { data } = useFETCH(`site-info/media`);

  let dataAll = data?.data.data;
  useEffect(() => {
    setFormData({
      facebook: dataAll?.whatsapp,
      whatsapp: dataAll?.facebook,
      youtube: dataAll?.youtube,
      instgram: dataAll?.instgram,
      twitter: dataAll?.twitter,
      email: dataAll?.email,
      phone_number1: dataAll?.phone_number1,
      phone_number2: dataAll?.phone_number2,
      phone_number3: dataAll?.phone_number3,
      website_url: dataAll?.website_url,
    });
  }, [dataAll]);
  const [formData, setFormData] = useState({
    facebook: "",
    whatsapp: "",
    youtube: "",
    instgram: "",
    twitter: "",
    email: "",
    phone_number1: "",
    phone_number2: "",
    phone_number3: "",
    website_url: "",
  }); // data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const post = (info, data) => {
    setLoading(true);
    setError("");
    axios
      .post(
        `${baseUrl}site-info/media`,
        { info: info, info_value: data },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((req) => {
        setLoading(false);
        setError("");
      })
      .catch((e) => {
        setError(e.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Container>
      <div>{error}</div>
      {loading ? <Loading /> : ""}
      <div className="flex flex-wrap mx-auto gap-5 mb-24 max-[380px]:mb-40">
        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <FaInstagram size={60} color="#CB3878" />
          <input
            type="text"
            value={formData.instgram}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, instgram: e.target.value })
            }
            placeholder="Add Instagram Link"
          />

          <div className=" ">
            <BiSave
              onClick={() => post("instgram", formData.instgram)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>

        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <FaFacebook size={60} color="#3B5998" />
          <input
            type="text"
            value={formData.facebook}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, facebook: e.target.value })
            }
            placeholder="Add Facebook Link"
          />
          <div className=" ">
            <BiSave
              onClick={() => post("facebook", formData.facebook)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>
        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <AiFillYoutube size={60} color="#E21A20" />{" "}
          <input
            type="text"
            value={formData.youtube}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, youtube: e.target.value })
            }
            placeholder="Add Youtube Link"
          />
          <div className=" ">
            <BiSave
              onClick={() => post("youtube", formData.youtube)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>
        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <AiOutlineWhatsApp size={60} color="#0DC143" />{" "}
          <input
            type="text"
            value={formData.whatsapp}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, whatsapp: e.target.value })
            }
            placeholder="Add Whatsapp Link"
          />
          <div className=" ">
            <BiSave
              onClick={() => post("whatsapp", formData.whatsapp)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>
        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <FaTwitter size={60} color="#2DAAE1" />
          <input
            type="text"
            value={formData.twitter}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, twitter: e.target.value })
            }
            placeholder="Add Twitter Link"
          />
          <div className=" ">
            <BiSave
              onClick={() => post("twitter", formData.twitter)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>
        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <MdOutlineMailOutline size={60} color="#707070" />{" "}
          <input
            type="text"
            value={formData.email}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            placeholder="Add Email Link"
          />
          <div className=" ">
            <BiSave
              onClick={() => post("email", formData.email)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>
        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <AiTwotonePhone size={60} color="#FEDADA" />{" "}
          <input
            type="text"
            value={formData.phone_number1}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, phone_number1: e.target.value })
            }
            placeholder="Add Phone Number One"
          />
          <div className=" ">
            <BiSave
              onClick={() => post("phone_number1", formData.phone_number1)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>
        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <AiTwotonePhone size={60} color="#9F7CC6" />{" "}
          <input
            type="text"
            value={formData.phone_number2}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, phone_number2: e.target.value })
            }
            placeholder="Add Phone Number Two"
          />
          <div className=" ">
            <BiSave
              onClick={() => post("phone_number2", formData.phone_number2)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>
        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <AiTwotonePhone size={60} color="#CB3878" />{" "}
          <input
            type="text"
            value={formData.phone_number3}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, phone_number3: e.target.value })
            }
            placeholder="Add Phone Number Three"
          />
          <div className=" ">
            <BiSave
              onClick={() => post("phone_number3", formData.phone_number3)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>
        <div className=" border-4 border-Brown rounded-xl flex flex-between p-4 w-[48%] max-lg:w-[100%] items-center relative bg-white">
          <MdOutlineLanguage size={60} color="#1B171F" />{" "}
          <input
            type="text"
            value={formData.website_url}
            name="text"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            onChange={(e) =>
              setFormData({ ...formData, website_url: e.target.value })
            }
            placeholder="Add Website Link"
          />
          <div className=" ">
            <BiSave
              onClick={() => post("website_url", formData.website_url)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </div>
      </div>

      <div className="fixed z-50 bottom-8 right-16 max-sm:right-2 max-sm:bottom-4 overflow-hidden">
        <div className="flex gap-5 flex-wrap max-sm:justify-end">
          <div
            onClick={() => window.history.go(-1)}
            className="bg-Brown cursor-pointer text-white font-semibold text-xl rounded-xl py-2 px-10"
          >
            Back
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactUsTwo;
