import Logo from "../../images/logo.png";
import { ButtonApp, Loading } from "../../components";
import { usePOST } from "../../APIs/useMyAPI";

const ChangePassword = () => {
  const { handleChange, loading, error, handleSubmit } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit("admin/change-password");
  };
  return (
    <div className="  h-[100vh] flex items-center justify-center text-center">
      {loading ? <Loading /> : ""}
      <div className="w-1/2 max-lg:w-[90%] flex flex-col justify-center space-y-6 border border-Brown p-10 rounded-2xl">
        <div className="flex justify-center">
          <img src={Logo} alt="" className="w-[200px] mx-auto" />
        </div>
        <div className="flex w-full mx-3">
          <input
            type="password"
            name="old_password"
            placeholder="old password"
            onChange={handleChange}
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none px-5"
          />
        </div>
        <div className="flex w-full mx-3">
          <input
            type="password"
            name="password"
            placeholder="new password "
            onChange={handleChange}
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none px-5"
          />
        </div>
        <div className="flex w-full mx-3">
          <input
            type="password"
            name="password_confirmation"
            placeholder="confirmation password "
            onChange={handleChange}
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none px-5"
          />
        </div>
        <div className="text-red-500 font-semibold ">{error}</div>
        <div onClick={handleFormSubmit} className="w-fit mx-auto">
          <ButtonApp>
            <span className="px-12">ارسال</span>
          </ButtonApp>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
