import { useContextHook } from "../../Context/ContextOPen";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import logo from "../../images/1-1.png";

function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  return (
    <div
      className={`${openMenu ? "w-[360px]" : " w-[0px]"} ${
        openMenu
          ? " max-sm:translate-x-0 max-sm:w-[100%] "
          : " max-sm:-translate-x-full"
      } h-[100vh] max-sm:h-[100%] sticky bg-Brown max-sm:fixed left-0 top-0 transition-all overflow-y-scroll  py-10 z-40`}
    >
      <div
        className={`max-sm:block hidden text-end mx-4 text-xl font-semibold text-white cursor-pointer`}
        onClick={changeMenu}
      >
        X
      </div>
      <div className="flex h-full justify-end">
        <ul className="text-black  text-end font-semibold space-y-4">
          <li className="">
            <img src={logo} alt="" className="bg-white mr-2" />
          </li>
          <li className=" ">
            <NavLink
              to={"/information"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-end"
            >
              Home Information
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to={"/aboutUs"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl hover:text-Brown hover:bg-white flex justify-end"
            >
              About Us
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to={"/events"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl hover:text-Brown hover:bg-white flex justify-end"
            >
              Exhibitions
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to={"/exhibition"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl hover:text-Brown hover:bg-white flex justify-end"
            >
              Events
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to={"/ourArtist"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl hover:text-Brown hover:bg-white flex justify-end"
            >
              Artists
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/news"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl hover:text-Brown hover:bg-white flex justify-end"
            >
              News
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/mediaCenter"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-end"
            >
              Media Center
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/contactUs"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-end"
            >
              Contact Us
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/Messages"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-end"
            >
              Messages
            </NavLink>
          </li>
          <li className=" pb-5 ">
            <NavLink
              to={"/Change-Password"}
              className="text-white text-xl py-2 px-5  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-end"
            >
              Change Password
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
